<template>
  <LayoutClassicTour>
    <TourClassicFeaturedVideosHeader
      v-if="currentSite.showFeaturedVideos && pageNumber == 1"
      :releases="featuredReleases.items"
    />

    <SectionContainer
      v-if="topRatedReleases && pageNumber == 1"
      :title="`Top Rated ${currentSite.name} Movies`"
      class="mt-1"
    >
      <div class="card-grid">
        <ReleaseCard
          v-for="(release, i) in topRatedReleases.items"
          :key="`top-rated-${i}`"
          :release="release"
          :show-rank="true"
          :show-rating="true"
          :vertical="useVerticalCards"
        />
      </div>
    </SectionContainer>

    <SectionContainer
      v-if="latestReleases && !pageIsBeyondVideos"
      :title="`Latest ${currentSite.name} Movies`"
    >
      <div class="card-grid">
        <ReleaseCard
          v-for="(release, i) in latestReleases.items"
          :key="`latest-${i}`"
          :release="release"
          :show-rating="false"
          :vertical="useVerticalCards"
        />
      </div>
    </SectionContainer>

    <SectionContainer v-if="tourImages && pageIsBeyondVideos">
      <div class="card-grid">
        <TourClassicImageAssetCard
          v-for="(image, i) in tourImages.items"
          :key="`tour-image-${i}`"
          :image="image"
        />
      </div>
    </SectionContainer>

    <Pagination
      :current-page="pageNumber"
      :total-pages="totalPageCount"
      resource=""
    />
  </LayoutClassicTour>
</template>

<script setup>
  const currentSite = inject("currentSite")
  const route = useRoute()
  const { isMobile } = useResponsive()
  const pageNumber = route.query.page || 1
  const perPage = isMobile.value ? 6 : 12
  const { useVerticalCards } = useSiteSettings(currentSite.value)

  useSiteSeo(currentSite, route, {
    title: () => {
      if (route.query.page > 1) {
        return `%siteDefaultTitle %separator Page: ${route.query.page}`
      } else {
        return "%siteDefaultTitle"
      }
    },
    titleTemplate: "%s %separator %siteNameWithTld",
    meta: [
      {
        name: "description",
        content: () => {
          if (route.query.page > 1) {
            return `%siteDefaultDescription %separator Page: ${route.query.page}`
          } else {
            return `%siteDefaultDescription`
          }
        },
      },
    ],
  })

  const [
    { data: topRatedReleases },
    { data: latestReleases },
    { data: featuredReleases },
  ] = await Promise.all([
    useApi(`/api/releases?sort=top_rated&per_page=${perPage}`),
    useApi(`/api/releases?sort=latest&page=${pageNumber}&per_page=${perPage}`),
    useApi("/api/releases?sort=featured&per_page=6"),
  ])

  const videoPageCount = latestReleases.value?.pagination?.totalPages || 0

  const pageIsBeyondVideos = pageNumber > 1 && videoPageCount < pageNumber
  const adjustedTourImagePageNumber = pageIsBeyondVideos
    ? pageNumber - videoPageCount
    : pageNumber

  const { data: tourImages } = await useApi(
    `/api/images?page=${adjustedTourImagePageNumber}&per_page=${perPage}`,
  )

  const totalPageCount =
    videoPageCount + tourImages.value?.pagination?.totalPages || 0

  if (pageIsBeyondVideos) {
    // TODO: This needs to be turned on for production
    // const header = useResponseHeader("Cache-Control")
    // header.value = "max-age=258000 public"
  }
</script>

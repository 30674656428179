<template>
  <div
    class="w-full max-w-7xl flex flex-row items-center justify-between gap-3 py-3 sm:p-0"
  >
    <div class="w-full">
      <NuxtLink to="/">
        <img :src="logoUrl" alt="Logo" class="w-full max-w-[250px]" />
      </NuxtLink>
    </div>

    <div
      class="w-full flex flex-row items-center justify-center sm:justify-end gap-3"
    >
      <NuxtLink class="link" :to="joinUrl">Movies</NuxtLink>
      <a
        v-if="currentSite.showTopRatedVideos"
        class="link"
        href="/tours/top-rated"
      >
        Top Rated
      </a>
      <NuxtLink class="link" to="/models">Models</NuxtLink>

      <UButton
        v-if="!inMembersArea"
        color="white"
        variant="outline"
        class="uppercase text-lg text-white border-4 rounded-none"
        to="/login"
      >
        Login
      </UButton>

      <UButton
        v-if="!inMembersArea"
        variant="outline"
        color="green"
        class="uppercase text-lg text-white border-4 border-green-400 rounded-none"
        :to="joinUrl"
      >
        Join Now
      </UButton>
    </div>
  </div>
</template>

<script setup>
  const route = useRoute()
  const { currentSite } = await useCurrentSite()
  const { label: exclusiveLabel } = useExclusiveSite(currentSite.value)
  const openSidebar = ref(false)
  const logoUrl = `/images/sites/${currentSite.value.slug}/logo.png`
  const joinUrl = currentSite.value.natsJoinUrl
  const { inMembersArea, inTourArea } = useMembersArea()
</script>

<style scoped>
  .link {
    @apply hidden sm:block text-xl text-white hover:text-gray-400 font-bold tracking-tight;
  }
</style>

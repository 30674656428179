<template>
  <AgeConfirmationPopup v-if="!userStore.ageConfirmed" />
  <div v-else>
    <TourPlusNavigation v-if="!atRoot" />
    <TourClassicNavigation v-if="!atRoot || (atRoot && route.query.page > 1)" />
    <div class="w-full flex flex-col items-center">
      <slot />
    </div>
    <Footer :join-button="false" />
  </div>

  <ClientOnly>
    <BillerEpochJoinBox />
    <SaleSpecial />
  </ClientOnly>
</template>

<script setup>
  const userStore = useUserStore()
  const route = useRoute()
  const atRoot = route.path === "/"

  useHead({
    bodyAttrs: {
      class: "tour-plus",
    },
  })
</script>

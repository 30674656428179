<template>
  <NuxtLink :to="fullImageUrl">
    <NuxtImg
      class="w-full h-auto"
      :src="image.thumbUrl"
      :alt="`${image.setName} ${image.imageName}`"
      loading="lazy"
    />
  </NuxtLink>
</template>

<script setup>
  const props = defineProps({
    image: Object,
  })

  const fullImageUrl = `/tour/image/images/${props.image.siteName}/${props.image.setName}/${props.image.imageName.split(".")[0]}/${props.image.id}`
</script>

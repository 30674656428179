<template>
  <LayoutPlusTour>
    <TourPlusFeaturedVideosHeader
      v-if="currentSite.showFeaturedVideos && pageNumber == 1"
      :releases="featuredReleases.items"
    >
      <TourPlusNavigation />
    </TourPlusFeaturedVideosHeader>

    <UDivider v-if="pageNumber == 1" class="mt-8">
      <span class="divider-text">Access Exclusive Porn+ Series</span>
    </UDivider>

    <SectionContainer v-if="pageNumber == 1" class="relative overflow-hidden">
      <SponsorSlider :sponsors="exclusiveSponsors" />
    </SectionContainer>

    <div
      v-if="pageNumber == 1"
      class="flex flex-col sm:flex-row items-center gap-4 my-12"
    >
      <UButton
        color="white"
        variant="outline"
        class="text-2xl border-4"
        size="xl"
      >
        Click here now to join PornPlus
      </UButton>
      <UButton
        color="green"
        variant="outline"
        class="text-2xl border-4 border-green-400 rounded-none leading-none"
        size="xl"
        to="/join"
      >
        Start Watching
      </UButton>
    </div>

    <UDivider v-if="pageNumber == 1" class="my-4">
      <span class="divider-text">Featured Videos</span>
    </UDivider>

    <SectionContainer
      v-if="featuredReleases && pageNumber == 1"
      class="mt-1 relative overflow-hidden"
    >
      <ReleaseSlider
        :releases="featuredReleases.items"
        :breakpoints="{
          320: { sliderPerGroup: 2, slidesPerView: 2, spaceBetween: 10 },
        }"
      />
    </SectionContainer>

    <Pagination
      v-if="pageNumber == 1"
      :current-page="pageNumber"
      :total-pages="totalPageCount"
      resource="/"
    />

    <UDivider v-if="pageNumber == 1" class="mt-12">
      <span class="divider-text">Porn+ has the videos you want</span>
    </UDivider>

    <SectionContainer
      v-if="topRatedReleases && pageNumber == 1"
      :title="`Top Rated ${currentSite.name} Movies`"
      class="mt-1"
    >
      <div class="card-grid">
        <ReleaseCard
          v-for="(release, i) in topRatedReleases.items"
          :key="`top-rated-${i}`"
          :release="release"
          :show-rank="true"
        />
      </div>
    </SectionContainer>

    <div
      v-if="pageNumber == 1"
      class="flex flex-col sm:flex-row items-center gap-4 my-12"
    >
      <UButton
        color="white"
        variant="outline"
        class="text-2xl border-4"
        size="xl"
      >
        Click here now to join PornPlus
      </UButton>
      <UButton
        color="green"
        variant="outline"
        class="text-2xl border-4 border-green-400 rounded-none leading-none"
        size="xl"
        to="/join"
      >
        Start Watching
      </UButton>
    </div>

    <SectionContainer
      v-if="latestReleases && !pageIsBeyondVideos"
      :title="`Latest ${currentSite.name} Movies`"
    >
      <div class="card-grid">
        <ReleaseCard
          v-for="(release, i) in latestReleases.items"
          :key="`latest-${i}`"
          :release="release"
          :show-rating="false"
        />
      </div>
    </SectionContainer>

    <div
      v-if="!pageIsBeyondVideos"
      class="flex flex-col sm:flex-row items-center gap-4 my-12"
    >
      <UButton
        color="white"
        variant="outline"
        class="text-2xl border-4"
        size="xl"
      >
        Click here now to join PornPlus
      </UButton>
      <UButton
        color="green"
        variant="outline"
        class="text-2xl border-4 border-green-400 rounded-none leading-none"
        size="xl"
        to="/join"
      >
        Start Watching
      </UButton>
    </div>

    <SectionContainer v-if="tourImages && pageIsBeyondVideos">
      <div class="card-grid">
        <TourClassicImageAssetCard
          v-for="(image, i) in tourImages.items"
          :key="`tour-image-${i}`"
          :image="image"
        />
      </div>
    </SectionContainer>

    <Pagination
      :current-page="pageNumber"
      :total-pages="totalPageCount"
      resource="/"
    />

    <div v-if="pageNumber == 1" class="top-content-producers-block">
      <div
        class="w-full mx-auto max-w-5xl my-6 flex flex-col sm:flex-row items-center justify-between px-4 sm:px-0 gap-2 sm:gap-4"
      >
        <div class="w-full flex flex-col gap-6">
          <div
            class="grid grid-cols-2 sm:grid-cols-3 items-center justify-center"
          >
            <NuxtImg
              v-for="(sponsor, i) in exclusiveSponsors.slice(0, 6)"
              :key="`tour-sponsor-${i}`"
              :src="sponsor.logoUrl"
              class="w-full p-4 justify-center justify-self-center mx-auto"
              loading="lazy"
            />
          </div>
          <p class="text-2xl font-light ml-4">And More &hellip;</p>
        </div>
        <div class="w-full flex flex-col items-end text-right gap-4">
          <p class="text-white/90 text-4xl font-bold uppercase leading-tight">
            &hellip; And movies from
            <br />
            today's top content
            <br />
            producers
          </p>
          <p class="text-xl">
            Get access to the most exclusive content from our first class
            producers.
          </p>

          <div class="flex flex-col items-end gap-4">
            <UButton
              color="white"
              variant="outline"
              class="text-2xl border-4"
              size="xl"
            >
              Click here now to join PornPlus
            </UButton>
            <UButton
              color="green"
              variant="outline"
              class="text-2xl border-4 border-green-400 rounded-none leading-none"
              size="xl"
              to="/join"
            >
              Start Watching
            </UButton>
          </div>
        </div>
      </div>
    </div>

    <UDivider v-if="pageNumber == 1" class="my-4">
      <span class="divider-text">The Hottest Performers All in One Place</span>
    </UDivider>

    <SectionContainer v-if="topRatedActors && pageNumber == 1">
      <div
        class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-3"
      >
        <ActorCard
          v-for="(actor, i) in topRatedActors.items"
          :key="i"
          :actor="actor"
        />
      </div>
    </SectionContainer>

    <div
      v-if="pageNumber == 1"
      class="flex flex-col sm:flex-row items-center gap-4 my-12"
    >
      <UButton
        color="white"
        variant="outline"
        class="text-2xl border-4"
        size="xl"
      >
        Click here now to join PornPlus
      </UButton>
      <UButton
        color="green"
        variant="outline"
        class="text-2xl border-4 border-green-400 rounded-none leading-none"
        size="xl"
        to="/join"
      >
        Start Watching
      </UButton>
    </div>

    <UDivider v-if="pageNumber == 1" class="mt-6 mb-4">
      <span class="divider-text">Porn+ Gives you the Best Value</span>
    </UDivider>

    <div
      v-if="pageNumber == 1"
      class="w-full max-w-5xl mx-auto my-12 flex flex-col sm:flex-row items-center justify-between font-semibold text-3xl text-center gap-4 sm:gap-0"
    >
      <div class="flex flex-col gap-6">
        <span>Daily Updates</span>
        <span>24/7/365 Support</span>
        <span>3,991 Premium Videos</span>
      </div>
      <div class="flex flex-col gap-6">
        <span>14 Categories</span>
        <span>2,136 Models</span>
        <span>71 Series</span>
      </div>
      <div class="flex flex-col gap-6">
        <span>Great on Mobile Devices</span>
        <span>Award-Winning Content</span>
        <span>As Low as $9.95/Month</span>
      </div>
    </div>

    <div class="flex flex-col sm:flex-row items-center gap-4 my-12">
      <UButton
        color="green"
        variant="outline"
        class="text-2xl sm:text-4xl border-4 border-green-400 rounded-none leading-none"
        size="xl"
        to="/join"
      >
        Get your password now
      </UButton>
    </div>
  </LayoutPlusTour>
</template>

<script setup>
  const currentSite = inject("currentSite")
  const route = useRoute()
  const pageNumber = route.query.page || 1

  useSiteSeo(currentSite, route, {
    title: () => {
      if (route.query.page > 1) {
        return `%siteDefaultTitle %separator Page: ${route.query.page}`
      } else {
        return "%siteDefaultTitle"
      }
    },
    titleTemplate: "%s %separator %siteNameWithTld",
    meta: [
      {
        name: "description",
        content: () => {
          if (route.query.page > 1) {
            return `%siteDefaultDescription %separator Page: ${route.query.page}`
          } else {
            return `%siteDefaultDescription`
          }
        },
      },
    ],
  })

  const [
    { data: exclusiveSponsors },
    { data: topRatedReleases },
    { data: latestReleases },
    { data: featuredReleases },
    { data: topRatedActors },
  ] = await Promise.all([
    useApi("/api/sponsors?exclusive=true"),
    useApi("/api/releases?sort=top_rated"),
    useApi(`/api/releases?sort=latest&page=${pageNumber}`),
    useApi("/api/releases?sort=featured&per_page=6"),
    useApi("/api/actors?sort=top_rated&vertical=true"),
  ])

  const videoPageCount = latestReleases.value.pagination.totalPages
  const pageIsBeyondVideos = pageNumber > 1 && videoPageCount < pageNumber
  const adjustedTourImagePageNumber = pageIsBeyondVideos
    ? pageNumber - videoPageCount
    : pageNumber

  const { data: tourImages } = await useApi(
    `/api/images?page=${adjustedTourImagePageNumber}`,
  )

  const totalPageCount = videoPageCount + tourImages.value.pagination.totalPages

  if (pageIsBeyondVideos) {
    // TODO: This needs to be turned on for production
    // const header = useResponseHeader("Cache-Control")
    // header.value = "max-age=258000 public"
  }
</script>

<style scoped>
  .divider-text {
    @apply text-3xl uppercase font-black;
  }

  button {
    &.outline {
      @apply text-xl font-semibold px-3 py-2;
    }

    &.solid {
      @apply text-xl font-semibold px-3 py-2;
      border: 3.5px solid rgb(0, 136, 94);
      background-color: rgb(0, 136, 94);
    }
  }

  .top-content-producers-block {
    background: rgb(0, 0, 0);
    background: linear-gradient(
      115deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(5, 40, 45, 1) 74%
    );

    @apply w-full flex items-center justify-center py-12 my-12;
    @apply border-t border-b border-white/20;
  }
</style>

<template>
  <ClientOnly>
    <div v-if="release" class="w-full sm:block">
      <div
        class="relative w-full flex flex-col items-center h-[400px] bg-black overflow-hidden"
      >
        <video
          :src="release.posterStreamUrl"
          :poster="release.posterUrl"
          autoplay
          muted
          loop
          preload
          playsinline
          class="absolute inset-0 w-full h-full object-cover"
        />

        <div
          class="absolute inset-0 bg-black/75 p-6 flex items-center justify-center"
        >
          <div class="w-full h-full max-w-7xl flex flex-col justify-between">
            <slot />

            <div class="flex flex-row items-end justify-between">
              <div class="w-full flex flex-col">
                <span
                  class="uppercase font-semibold text-5xl xl:text-6xl drop-shadow leading-none tracking-tighter"
                >
                  The Best Value
                  <br class="block lg:hidden" />
                  in Porn
                </span>
                <span
                  class="uppercase text-2xl xl:text-3xl flex font-semibold tracking-tight drop-shadow-md"
                >
                  71 Series | 3,900 Movies
                </span>

                <div class="w-full flex sm:hidden flex-col mt-3">
                  <p class="lg:text-4xl text-2xl leading-tight font-extralight">
                    {{ release.title }}
                  </p>
                  <p>
                    <span
                      v-for="(actor, i) in release.actors"
                      :key="`feature-header-actor-${i}`"
                    >
                      <a
                        :href="`/models/${actor.cached_slug}`"
                        class="text-white font-light text-lg"
                      >
                        {{ actor.name }}
                      </a>
                      <span v-if="i < release.actors.length - 1">,&nbsp;</span>
                    </span>
                  </p>
                </div>

                <div class="mt-4">
                  <UButton
                    color="green"
                    variant="outline"
                    class="w-full sm:w-auto text-2xl border-green-400 flex flex-col items-center"
                    size="xl"
                  >
                    Start Watching
                  </UButton>
                </div>
              </div>
              <div class="w-full hidden sm:flex flex-col items-end justify-end">
                <p class="lg:text-4xl text-2xl leading-tight font-extralight">
                  {{ release.title }}
                </p>
                <p>
                  <span
                    v-for="(actor, i) in release.actors"
                    :key="`feature-header-actor-${i}`"
                  >
                    <a
                      :href="`/models/${actor.cached_slug}`"
                      class="text-white font-light text-lg"
                    >
                      {{ actor.name }}
                    </a>
                    <span v-if="i < release.actors.length - 1">,&nbsp;</span>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ClientOnly>
</template>

<script setup>
  const props = defineProps({
    releases: Array,
  })

  const release = ref(null)

  onMounted(() => {
    release.value =
      props.releases[Math.floor(Math.random() * props.releases.length)]
  })
</script>
